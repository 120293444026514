import React from "react";

function App(props) {
    return (
        <>
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={"greater-than-icon " + [props.extraClass]}
                      d="M0.919922 1.15881L8.78689 9.02183C8.88553 9.12048 8.88553 9.28028 8.78689 9.37853L0.919922 17.2455"
                      stroke="black" strokeWidth="2" strokeMiterlimit="10" />
            </svg>

        </>
    );
}

export default App