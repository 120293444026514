import React, {useState} from "react"
import HelpCenterSearch from "../../components/help-center/helpCenterSearch";
import HelpCenterSearchIndex from "../../components/help-center/helpCenterSearchIndex";
import HelpCenterLinks from "../../components/help-center/helpCenterLinks"
import useTranslations from "../../utils/useTranslations";

export default ({location, data, pageContext: {text:text}}) => {
    const t = useTranslations(text)
    const search = location.search;
    const value = new URLSearchParams(search).get('value');
    const locale = "en";

    const filtersInitialState = [
        {name: "BitTorrent Android",    checked: false, id: HelpCenterLinks.BT_ANDROID},
        {name: "BitTorrent Classic",    checked: false, id: HelpCenterLinks.BT_CLASSIC},
        {name: "BitTorrent Speed",      checked: false, id: HelpCenterLinks.BT_SPEED},
        {name: "BitTorrent Web",        checked: false, id: HelpCenterLinks.BT_WEB},
        // {name: "BitTorrent Filesystem", checked: false, id: HelpCenterLinks.BT_CLASSIC.},

        {name: "uTorrent Android",      checked: false, id: HelpCenterLinks.UT_ANDROID},
        {name: "uTorrent Classic",      checked: false, id: HelpCenterLinks.UT_CLASSIC},
        {name: "uTorrent Web",          checked: false, id: HelpCenterLinks.UT_WEB},
    ]

    const [productFilters, updateFilters] = useState(filtersInitialState);

    let filters = [];


    for(let filter of productFilters){
        if(filter.checked){
            filters.push(filter.id);
        }
    }

    let filtersSelected = filters.length > 0;



    function clearFilters(){
        if(filtersSelected){
            updateFilters(filtersInitialState)
        }
    }

    function checkBoxClicked(index) {
        let obj = productFilters[index];
        obj.checked = !obj.checked;


        let filters = [...productFilters];
        filters[index] = obj;

        updateFilters(filters)
    }




    return (
        <div className="search-page">
            <HelpCenterSearch text={text}/>

            <div className="search-body">
                <div>
                    <div className={"filter-header"}>
                        <div className={"filter-button " + (filtersSelected ? "filter-button-active" : "")} onClick={()=>clearFilters()}>
                            {t("clearFilters")}
                        </div>
                    </div>



                    <div className={"filter-column"}>
                        <div className={"filter-chips"} key={"chips"}>
                            {productFilters.map((product, index) => <div  key={product.name + "-chip-holder"}>
                                {product.checked &&   <div className={"chip"} key={product.name+"-chip"}>
                                <div>
                                    {product.name}
                                </div>
                                <div className={"remove-chip"} onClick={()=>checkBoxClicked(index)}>
                                    X
                                </div>
                            </div>}
                            </div>)}

                        </div>

                        {filtersSelected && <div className="divider" key={"divider"} />}

                        {productFilters.map((product, index) => <div className="filter-row" key={product.name + product.checked}>
                            <input id={"checkbox-" + product.name}
                                   className="filter-checkbox"
                                   type="checkbox"
                                   checked={product.checked}
                                   onChange={() => checkBoxClicked(index)} />
                            <label htmlFor={"checkbox-" + product.name} className="filter-name">{product.name}</label>

                            {product.checked}
                        </div>)}
                    </div>
                </div>
                <div className="results-column">
                    <HelpCenterSearchIndex filterList={filters} query={value} text={text}/>
                </div>



            </div>


        </div>
    )
}