import React, { Component } from "react"
import Axios from "axios"
import { Link } from "gatsby"
import * as JsSearch from "js-search"
import PageLeftIcon from "../../images/icons/pageLeftIcon";
import PageRightIcon from "../../images/icons/pageRightIcon";
import useTranslations from "../../utils/useTranslations";

const ARTICLES_PER_PAGE = 8;

class helpCenterSearchIndex extends Component {

  constructor(props){
    super(props);
    this.state = {
      articleList: [],
      dataToSearch: [],
      searchResults: [],
      filteredResults: [],
      isError: false,
      searchQuery: "",
      numResults: 0,
      pageIndex: 0,
    };
  }

  /**
   * React lifecycle method to fetch the data
   */
  async componentDidMount() {

    Axios.get("/search-info.json")
      .then(result => {
        const searchInfo = result.data
        this.setState({ articleList: searchInfo.articles })
        this.rebuildIndex()
        this.applySearchQuery(this.props);
      })
      .catch(err => {
        this.setState({ isError: true })
        console.log("====================================")
        console.log(`Something bad happened while fetching the data\n${err}`)
        console.log("====================================")
      })
  }

  componentWillReceiveProps(nextProps) {
    const prevProps = this.props
    if(nextProps.query !== prevProps.query) {
      this.applySearchQuery(nextProps);
    } else if (nextProps.filterList !== prevProps.filterList) {
      this.filterSearchResults(this.state.searchResults, nextProps.filterList)
    }
    this.setState({pageIndex: 0})
  }

  applySearchQuery(props) {
    const query = props.query
    this.setState({searchQuery: query })
    this.searchData(query)
  }

  filterSearchResults(searchResults, filterList) {
    let filteredResults = (filterList.length > 0) ? searchResults.filter((result) => {
      return filterList.includes(result.productId)
    }) : searchResults
    this.setState({filteredResults: filteredResults, numResults: filteredResults.length })
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const { articleList } = this.state
    const dataToSearch = new JsSearch.Search("id")
    /**
     * defines an indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id")

    dataToSearch.addIndex("title") // sets the index attribute for the data
    dataToSearch.addIndex("content") // sets the index attribute for the data
    dataToSearch.addIndex("productName") // sets the index attribute for the data

    dataToSearch.addDocuments(articleList) // adds the data to be searched
    this.setState({ dataToSearch: dataToSearch })
  }

  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = (query) => {
    const { dataToSearch } = this.state
    const searchValue = query
    const queryResult = dataToSearch.search(searchValue)
    this.setState({ searchQuery: searchValue, searchResults: queryResult })
    this.filterSearchResults(queryResult, this.props.filterList)
  }

  dateDisplay = (isoString) => {
      let date = new Date(isoString);
      return date.toLocaleString()
  }

  updatePage = (isForward) => {
        if (isForward) {
            if ((this.state.pageIndex + 1) * ARTICLES_PER_PAGE < this.state.filteredResults.length - 1) {
                this.setState({pageIndex: this.state.pageIndex+1})
            }
        } else {
            if (this.state.pageIndex > 0) {
                this.setState({pageIndex: this.state.pageIndex-1})
            }
        }
    }

  render() {
    const { articleList, filteredResults, searchQuery, numResults } = this.state
    const queryResults = searchQuery === "" ? [] : filteredResults.slice(ARTICLES_PER_PAGE*this.state.pageIndex, (ARTICLES_PER_PAGE*this.state.pageIndex)+ARTICLES_PER_PAGE)
    const re = new RegExp('(?<!>)\\b'+searchQuery+'\\b(?!>)', 'ig');
    const t = useTranslations(this.props.text)

    return (
      <div className="results-column">
        {
          searchQuery !== "" && typeof searchQuery === "string" &&
          <>
            <div className="results-header">
              {t("searchResults",{numResults:numResults,searchQuery:searchQuery})}
            </div>
            <div className="articles">
            {
              queryResults.map(item => {
                let highlightedTitle = item.title.replaceAll(re, `<p class="highlighted">$&<p>`)
                let highlightedContent = (item.content).replaceAll(re, '<p class="highlighted">$&<p>')
                return (
                  <div className="article-result" key={item.id}>
                      <Link to={item.link}>
                          <div className="article-title" dangerouslySetInnerHTML={{__html:highlightedTitle}}></div>
                          <div
                              className="article-details">{item.folderName} | {this.dateDisplay(item.createdAt)}</div>
                          <div className="article-content" dangerouslySetInnerHTML={{__html:highlightedContent}}></div>
                      </Link>
                  </div>
                )
              })
            }
            </div>
            <div className={"page-selectors"}>
                <span className={(this.state.pageIndex > 0 ? "pointer " : " ") + "page-selector "} onClick={() => {
                    this.updatePage(false)
                }}>
                    <PageLeftIcon extraClass={this.state.pageIndex > 0 ? "" : "grey"} />
                </span>
                <span
                    className={((this.state.pageIndex + 1) * ARTICLES_PER_PAGE < this.state.filteredResults.length - 1 ? "pointer " : " ") + "page-selector "}
                    onClick={() => {
                        this.updatePage(true)
                    }}>
                    <PageRightIcon extraClass={(this.state.pageIndex + 1) * ARTICLES_PER_PAGE < this.state.filteredResults.length - 1 ? "" : "grey"} />
                </span>
            </div>
          </>
        }
      </div>
    )
  }
}

export default helpCenterSearchIndex