import React from "react";

function App(props) {
    return (
        <>
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    className={"less-than-icon "+[props.extraClass]}
                    d="M8.94092 17.2455L1.07395 9.38246C0.97531 9.28382 0.97531 9.12402 1.07395 9.02577L8.94092 1.15881"
                    stroke="#000" strokeWidth="2" strokeMiterlimit="10" />
            </svg>
    </>
    );
}

export default App